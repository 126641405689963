import algosdk, { Algodv2 } from "algosdk";
import { Network } from "../ConfigService";
import { UtilService } from "../UtilService";
class AlgorandService {
  public static async optInAlgorandAsset(assetID: number, algodClient: any) {
    // Allow to opt in asset for algorand
    // todo: implement the logic, for now some of the placeholders for core logic added

    let params = await algodClient.getTransactionParams().do();
    params.fee = 1000;
    params.flatFee = true;

    // todo: add address
    // let sender = recoveredAccount3.addr;
    let sender = "";
    let recipient = sender;
    let revocationTarget = undefined;
    let closeRemainderTo = undefined;
    let amount = 0;

    // signing and sending "txn" allows sender to begin accepting asset specified by creator and index
    await algosdk.makeAssetTransferTxnWithSuggestedParams(
      sender,
      recipient,
      closeRemainderTo,
      revocationTarget,
      amount,
      undefined,
      assetID,
      params
    );
    // let rawSignedTxn = opttxn.signTxn(recoveredAccount3.sk);
    // let opttx = await algodClient.sendRawTransaction(rawSignedTxn).do();
    // console.log("Transaction : " + opttx.txId);
    // // wait for transaction to be confirmed
    // await waitForConfirmation(algodClient, opttx.txId);
  }

  public static extractAlgorandAssetId(assetId: string): string {
    const a1Prefix = "a5537";
    const removedPrefix = assetId.replace(a1Prefix, "");
    const removedZeros = removedPrefix.replace(/^0+/, "");
    return removedZeros ?? assetId;
  }

  public static extractSymbolFromName(assetName: string): string {
    const a1SymbolIdSplitter = "-";
    const data = assetName.split(a1SymbolIdSplitter)[0];
    return data;
  }

  public static getAlgodClient = (algodUrl?: string, algodApiKey?: string): algosdk.Algodv2 => {
    let algodClient: Algodv2;
    // if api key provided use purestake or any other service
    // if not then use default algorand testnet, but account info can be fetched
    if (UtilService.isValidStr(algodApiKey) && UtilService.isValidStr(algodUrl)) {
      const algoServer = algodUrl as string;
      const API_KEY = { "X-Algo-API-Token": algodApiKey as string };
      algodClient = new algosdk.Algodv2(API_KEY, algoServer, "");
    } else {
      // no or incorrect api key provided. No balance will be fetched
      algodClient = new algosdk.Algodv2("", "https://node.testnet.algoexplorerapi.io", "");
    }
    return algodClient;
  };

  // Symbol for Algorand must be fetched this way since, there's no storage for short unit names anywhere in the bridge for now
  // hence if any token has symbol name deployed on EVM which is incompatible with Metamask (name is too long), we need to get its name from somewhere else
  public static async getAssetSymbolFromAlgorand(
    algorandAssetId: string,
    selectedNetwork: Network
  ): Promise<string> {
    const algodClient = AlgorandService.getAlgodClient(
      selectedNetwork.mainchainDetais?.nodeUrl,
      selectedNetwork.mainchainDetais?.apiKey
    );
    try {
      const assetDetails = await algodClient.getAssetByID(Number(algorandAssetId)).do();
      const symbol = assetDetails.params["unit-name"] ?? algorandAssetId;
      return symbol;
    } catch (e) {
      console.error(e);
      return "";
    }
  }
}

export default AlgorandService;
