import { useCallback } from "react";
import { useQuery } from "react-query";
import { AssetElement, getAssetList } from "../../Services/BridgeService";
import { MAIN_ASSET_ID, MILKOMEDA_BLOCK_TIME_SECONDS } from "../../Constants";
import Loader from "../../Components/UI/Atoms/Loader";
import { useAppProvider } from "../../context/AppContext";
import TokensService from "../../Services/TokensService";
import { UtilService } from "../../Services/UtilService";
import CopyToClipboard from "../../Components/UI/Atoms/CopyToClipboard";
import { Evm } from "../../Services/EVM/Evm";

import MetamaskIcon from "../../Assets/logos/iconMetamask.svg";
import * as Tooltip from "@radix-ui/react-tooltip";

// TODO: remove hardcoded list of assets, so end-users are not confused
// Context of adding hardcoded list: until the c1-indexer-mainnet is resynced, we need to hide some of the tokens
// c1-indexer had a bug which caused removal of the tokens not to work correctly hence we need to hide them in UI (since to fix that resyncing indexer waits in line)
const removedAssets = ["GERO", "CARDS", "CHARLI3", "IAG"];

function Tokens() {
  const { selectedNetwork } = useAppProvider();

  const filterOutIncompleteAssets = useCallback(
    (listOfAssets: AssetElement[]): AssetElement[] => {
      const assetsList: AssetElement[] = [];
      for (let asset of listOfAssets) {
        if (asset.asset_id === MAIN_ASSET_ID) {
          switch (selectedNetwork.mainchain) {
            case "Cardano":
              asset.symbol = "MADA";
              break;
            case "Algorand":
              asset.symbol = "MALGO";
              break;
            default:
              asset.symbol = "WMAIN";
              break;
          }
        }
        if (asset.symbol === null) continue;
        assetsList.push(asset);
      }
      return assetsList;
    },
    [selectedNetwork.mainchain]
  );

  const {
    isLoading: isAssetsListLoading,
    isSuccess: isAssetsListSuccess,
    data: assetsList,
  } = useQuery(
    ["assetsList"],
    async () => {
      const response = await getAssetList({
        baseUrl: selectedNetwork.bridgeApiBaseURL,
        // todo: allow to set different values in the UI
        active: true,
      });
      let assets = filterOutIncompleteAssets(response as AssetElement[]);

      if (selectedNetwork.mainchain === "Cardano") {
        assets = assets.filter((asset) => !removedAssets.includes(asset.symbol));
      }
      return assets;
    },
    {
      refetchInterval: MILKOMEDA_BLOCK_TIME_SECONDS,
    }
  );

  return (
    <div className="position mx-auto flex w-full max-w-7xl flex-wrap px-5 py-9 pt-24">
      <div className="flex w-full flex-wrap content-between pt-2 pb-6">
        <div className="mb-2 flex w-full flex-col justify-between md:mb-3 md:flex-row md:items-center">
          <div className="flex items-center">
            <h1 className="text-lg font-bold text-white">
              Accepted tokens by the Milkomeda Bridge
            </h1>
          </div>
          <a
            href="https://airtable.com/shr8k4usXQFhtDjJf"
            target="_blank"
            className="pt-5 text-sm text-white underline md:px-5 md:pt-0"
          >
            Add Token to the Bridge
          </a>

          {/* {selectedNetwork.mainchain === "Algorand" && (
            <div className="flex flex-col md:flex-row items-center">
              <button type="submit" className="primary-btn w-full md:w-auto mt-4 md:mt-0">
                Connect Wallet
              </button>
            </div>
          )} */}
        </div>
        {isAssetsListLoading ? (
          <div className="mt-5 flex flex-1 items-center justify-center md:mt-10 md:h-9">
            <Loader className="text-orange-600 md:h-9" />
          </div>
        ) : (
          <div className="mt-2 w-full overflow-x-auto text-white">
            <table className="table w-full ">
              <thead>
                <tr>
                  <th scope="col" className="left">
                    Symbol
                  </th>
                  <th scope="col">{selectedNetwork.mainchain} Asset ID</th>
                  <th scope="col">{selectedNetwork.mainchain} Decimals</th>
                  <th scope="col" className="left">
                    Milkomeda Asset ID
                  </th>
                  <th scope="col" className="left">
                    Milkomeda Token Contract
                  </th>
                  <th scope="col">Milkomeda Decimals</th>
                  {selectedNetwork.mainchain === "Cardano" && <th scope="col">TVL</th>}
                  {/* <th scope="col">TVL</th> */}
                  {/* {selectedNetwork.mainchain === "Algorand" && (
                    <th scope="col">
                      Mainchain Action
                    </th>
                  )} */}
                  {/* <th scope="col">
                  Metamask
                </th> */}
                </tr>
              </thead>
              <tbody>
                {isAssetsListSuccess &&
                  assetsList?.map((asset: AssetElement) => {
                    return (
                      <tr key={asset.asset_id}>
                        <td className="left">
                          <div className="flex items-center">
                            <span>{asset.symbol} </span>
                            {asset.asset_id !== MAIN_ASSET_ID ? (
                              <Tooltip.Provider delayDuration={0}>
                                <Tooltip.Root>
                                  <Tooltip.Trigger
                                    className="ml-2 inline h-8 w-8 cursor-pointer items-center justify-center rounded-full p-2 hover:bg-gray-800/80"
                                    onClick={async () => {
                                      await Evm.switchEthChain(selectedNetwork.evmDetails);
                                      await Evm.handleAddAssetToMetamask(asset, selectedNetwork);
                                    }}
                                  >
                                    <img
                                      className="w-full"
                                      src={MetamaskIcon}
                                      alt="Add asset to Metamask icon"
                                    />
                                  </Tooltip.Trigger>
                                  <Tooltip.Content
                                    sideOffset={3}
                                    className="animate-fadeIn rounded-md bg-slate-900 py-2 px-3 text-center text-sm text-white shadow-lg duration-75 ease-out"
                                  >
                                    <Tooltip.Arrow className="fill-slate-900" />
                                    Add Asset to MetaMask
                                  </Tooltip.Content>
                                </Tooltip.Root>
                              </Tooltip.Provider>
                            ) : null}
                          </div>
                        </td>
                        <td>
                          <div className="w-[180px] flex-wrap items-center whitespace-normal">
                            <a
                              href={`${
                                selectedNetwork.mainchainExplorerBaseURL
                              }/${UtilService.getMainchainExplorerAssetUrlBase(
                                selectedNetwork.mainchain
                              )}/${TokensService.getAssetId(selectedNetwork.mainchain, asset)}`}
                              target="_blank"
                              rel="noreferrer"
                              className="underline"
                            >
                              {TokensService.getAssetId(selectedNetwork.mainchain, asset)}
                            </a>
                          </div>
                        </td>
                        <td>{asset.mainchain_decimals ?? 18}</td>
                        <td className="left">
                          <div className="w-[180px] flex-wrap items-center whitespace-normal">
                            <CopyToClipboard text={asset.asset_id}>
                              {UtilService.truncateString(asset.asset_id)}
                            </CopyToClipboard>
                          </div>
                        </td>
                        <td className="left">
                          <a
                            href={`${selectedNetwork.milkomedaExplorerBaseURL}/address/${asset.token_contract}`}
                            target="_blank"
                            rel="noreferrer"
                            className="block w-[180px] whitespace-normal underline"
                          >
                            {asset.token_contract}
                          </a>
                        </td>
                        <td>
                          {asset.sidechain_decimals != null ||
                          asset.sidechain_decimals !== undefined
                            ? asset.sidechain_decimals
                            : 18}
                        </td>
                        {selectedNetwork.mainchain === "Cardano" && (
                          <td>
                            <p className="w-[80px] flex-wrap items-center whitespace-normal">
                              {asset.tvl
                                ? UtilService.formatCompactNumber(
                                    UtilService.decimalsToMainUnitConversion(
                                      asset.tvl.toString(),
                                      asset.sidechain_decimals
                                    )
                                  )
                                : "-"}
                            </p>
                          </td>
                        )}
                        {/* <td>-</td> */}
                        {/* {selectedNetwork.mainchain === "Algorand" && (
                          <td className="text-sm font-light px-6 py-4 break-words sticky">
                            <button>Opt in</button>
                          </td>
                        )} */}

                        {/* <td className="text-sm font-light px-6 py-4 max-w-xs break-words">
                        <button>Add to Metamask</button>
                      </td> */}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}

export default Tokens;
