import AssetFingerprint from "@emurgo/cip14-js";
import { MAIN_ASSET_ID } from "../../Constants";
import { AssetElement } from "../BridgeService";

const ASSET_INITIAL_BYTES = 40;
class CardanoService {
  public static convertToAssetId(assetFromMilkomeda: AssetElement): string {
    if (assetFromMilkomeda.asset_id === MAIN_ASSET_ID) return "";

    if (assetFromMilkomeda.cardano_fingerprint) {
      return assetFromMilkomeda.cardano_fingerprint;
    }
    // For Cardano we could just return the fingerprint, but some of the tokens doesn't have it on devnet indexer db,
    // so we use CIP14 to create an asset it
    const fingerprint = AssetFingerprint.fromHash(
      Buffer.from(assetFromMilkomeda.asset_id.substring(0, ASSET_INITIAL_BYTES), "hex")
    ).fingerprint();

    return fingerprint;
  }
}

export default CardanoService;
