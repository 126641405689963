import React from "react";
import Arrow from "../../../../Assets/arrow.svg";
import Milkicon from "../../../../Assets/icon-milk.svg";
import { MAIN_ASSET_ID } from "../../../../Constants";
import {
  ProcessedBridgeRequestResponse,
  ProcessedBridgeUnwrappingResponse,
  ProcessedBridgeWrappingResponse,
} from "../../../../Types";
import { AssetDataMapItem, RequestType, UtilService } from "../../../../Services/UtilService";
import { ethers } from "ethers";
import { useAppProvider } from "../../../../context/AppContext";
import { Networks, networksMap } from "../../../../Services/ConfigService";
import AlgorandService from "../../../../Services/Mainchains/AlgorandService";

type TransactionDataProps = {
  wrapItem?: ProcessedBridgeWrappingResponse;
  unwrapItem?: ProcessedBridgeUnwrappingResponse;
  assetDataMap?: Record<string, AssetDataMapItem>;
  requestItem?: ProcessedBridgeRequestResponse;
  requestType: RequestType;
};

type RequestItemDataProps = {
  data?: ProcessedBridgeRequestResponse;
  assetDataMap?: Record<string, AssetDataMapItem>;
};

const ExternalLink = ({ children, href }: { children: React.ReactNode; href: string }) => (
  <a
    className="transition duration-300 ease-out hover:text-sky-400"
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    onClick={(e) => e.stopPropagation()}
  >
    {children}
  </a>
);

const WrapData = ({ data: wrapItem }: RequestItemDataProps) => {
  const { selectedNetwork } = useAppProvider();

  let mainValue =
    wrapItem?.assets.find((asset) => asset.asset_id === MAIN_ASSET_ID)?.asset_value ?? 0;

  if (
    selectedNetwork.name === networksMap[Networks.algorandDevnet].name ||
    selectedNetwork.name === networksMap[Networks.algorandMainnet].name
  ) {
    mainValue = UtilService.formatValue(
      mainValue as string,
      MAIN_ASSET_ID,
      undefined,
      selectedNetwork.mainAssetDecimals,
      selectedNetwork.name
    );
  }

  if (
    selectedNetwork.name === networksMap[Networks.cardanoDevnet].name ||
    selectedNetwork.name === networksMap[Networks.cardanoMainnet].name
  ) {
    mainValue = UtilService.formatValue(
      mainValue as string,
      MAIN_ASSET_ID,
      undefined,
      6,
      selectedNetwork.name
    );
  }

  let maValue, maSymbol;
  let token = wrapItem?.assets.find((asset) => asset.asset_id !== MAIN_ASSET_ID);
  maValue = token?.asset_value;

  if (token) {
    maSymbol = wrapItem?.assets.find((asset) => asset.asset_id !== MAIN_ASSET_ID)?.symbol;

    // m1-source needs to get token symbol and not name
    // so for now cleanup symbol-assetid for Algorand Mainnet
    if (selectedNetwork.id === Networks.algorandMainnet && maSymbol) {
      maSymbol =
        selectedNetwork.id === Networks.algorandMainnet
          ? AlgorandService.extractSymbolFromName(maSymbol)
          : maSymbol;
    }

    maValue = ethers.utils
      .formatUnits(token.asset_value, token.mainchain_decimals ?? 0)
      .toString()
      .toLocaleString();
  }

  let confirmationTimeS = wrapItem?.executed_timestamp === 0 ? wrapItem?.executed_timestamp : null;
  if (wrapItem?.timestamp && wrapItem?.executed_timestamp) {
    confirmationTimeS = wrapItem?.executed_timestamp - (wrapItem?.timestamp as unknown as number);
  }

  const showMainValueWithFee = Number(mainValue) <= 0;
  return (
    <div>
      {showMainValueWithFee === false && (
        <p className="my-2 text-sm font-semibold text-white md:my-0">
          <span className="text-gray-200">{selectedNetwork?.wrapAssetSymbol} (Wrapped): </span>
          {mainValue !== "0" && <span>{mainValue} </span>}
          <span className="text-gray-200">({selectedNetwork.wrappingFee} fee)</span>
        </p>
      )}

      {maSymbol != null && (
        <p className="my-2 text-sm font-semibold text-white md:my-0">
          <span className="text-gray-200">Token: </span>
          {maValue} {maSymbol}
        </p>
      )}
      {/* <p className="my-2 text-sm font-semibold text-white md:my-0">
        <span className="text-gray-200">Tx ID (Milkomeda): </span>
        <span className="break-all underline">
          <ExternalLink
            href={`${selectedNetwork.milkomedaExplorerBaseURL}/tx/${wrapItem?.transaction_id}`}
          >
            {wrapItem?.transaction_id ?? "N.A. - awaiting for evm side"}
          </ExternalLink>
        </span>
      </p> */}
      {/* <p className="my-2 text-sm font-semibold text-white md:my-0">
        <span className="text-gray-200">Tx ID ({selectedNetwork.mainchain}): </span>
        <span className="break-all underline">
          <ExternalLink
            href={`${
              selectedNetwork.mainchainExplorerBaseURL
            }/${UtilService.getMainchainExplorerTxUrlBase(selectedNetwork.mainchain)}/${
              wrapItem?.mainchain_tx_id
            }`}
          >
            <b>{wrapItem?.mainchain_tx_id}</b>
          </ExternalLink>
        </span>
      </p> */}
      <p className="my-2 text-sm font-semibold text-white md:my-0">
        <span className="text-gray-200">Timestamp: </span>
        {wrapItem?.timestamp
          ? new Date((wrapItem.timestamp as unknown as number) * 1000).toLocaleString(undefined, {
              dateStyle: "long",
              timeStyle: "long",
            })
          : null}
      </p>
      <p className="my-2 text-sm font-semibold text-white md:my-0">
        <span className="text-gray-200">Confirmation Time: </span>{" "}
        {UtilService.informAboutWrappingConfirmation(confirmationTimeS, wrapItem?.invalidated)}
      </p>
      <div className="mt-2 flex flex-col items-start break-all md:mt-4 md:flex-row md:items-center">
        <div className="flex md:flex-1 md:basis-full">
          <img
            className="mr-4 w-8 min-w-[2rem]"
            src={selectedNetwork?.iconCard}
            alt={selectedNetwork?.mainchain}
          />
          <div>
            <p className="my-2 text-sm font-semibold text-white md:my-0">
              <span className="text-gray-200">From</span>
            </p>
            <p className="my-2 text-sm font-semibold text-white md:my-0">
              <span className="break-all underline">
                <ExternalLink
                  href={`${
                    selectedNetwork.mainchainExplorerBaseURL
                  }/${UtilService.getMainchainExplorerTxUrlBase(selectedNetwork.mainchain)}/${
                    wrapItem?.mainchain_tx_id
                  }`}
                >
                  {wrapItem?.mainchain_tx_id}
                </ExternalLink>
              </span>
            </p>
          </div>
        </div>
        <div className="hidden md:block md:min-w-[60px] md:pb-0">
          <img className="mx-auto" src={Arrow} alt="" />
        </div>
        <div className="flex md:flex-1 md:basis-full">
          <img className="mr-4 w-8 min-w-[2rem]" src={Milkicon} alt="Milkomeda" />
          <div>
            <p className="my-2 text-sm font-semibold text-white md:my-0">
              <span className="text-gray-200">To</span>
            </p>
            <p className="my-2 text-sm font-semibold text-white md:my-0">
              <span className="break-all underline">
                <ExternalLink
                  href={`${selectedNetwork.milkomedaExplorerBaseURL}/tx/${wrapItem?.transaction_id}`}
                >
                  {wrapItem?.to}
                </ExternalLink>
                {/* <ExternalLink
                  href={`${selectedNetwork.milkomedaExplorerBaseURL}/address/${wrapItem?.to}`}
                >
                  <b>{wrapItem?.to}</b>
                </ExternalLink> */}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const UnwrapData = ({ data: unwrapItem, assetDataMap }: RequestItemDataProps) => {
  const { selectedNetwork } = useAppProvider();

  let mainAssetValue =
    unwrapItem?.assets.find((asset) => asset.asset_id === MAIN_ASSET_ID)?.asset_value ?? "0";

  mainAssetValue = UtilService.formatValue(
    mainAssetValue ?? "0",
    MAIN_ASSET_ID,
    assetDataMap ?? {},
    undefined
  );

  let maValue, maSymbol, maAssetId;
  let token = unwrapItem?.assets.find((asset) => asset.asset_id !== MAIN_ASSET_ID);
  maValue = token?.asset_value;

  if (token) {
    maAssetId = token.asset_id;
    maSymbol =
      unwrapItem?.assets.find((asset) => asset.asset_id !== MAIN_ASSET_ID)?.symbol ?? "N.A.";

    // m1-source needs to get token symbol and not name
    // so for now cleanup symbol-assetid for Algorand Mainnet
    if (selectedNetwork.id === Networks.algorandMainnet && maSymbol) {
      maSymbol =
        selectedNetwork.id === Networks.algorandMainnet
          ? AlgorandService.extractSymbolFromName(maSymbol)
          : maSymbol;
    }

    maValue = maValue = ethers.utils
      .formatUnits(token.asset_value, token.sidechain_decimals ?? 0)
      .toString()
      .toLocaleString();
  }

  let confirmationTimeS = unwrapItem?.executed_timestamp ?? null;
  if (unwrapItem?.timestamp && unwrapItem?.executed_timestamp) {
    confirmationTimeS =
      unwrapItem?.executed_timestamp - (unwrapItem?.timestamp as unknown as number);
  }

  const isERC20Unwrapped = maValue != null && maAssetId != null;
  // for Cardano there's at least 3 ADA + wrapping fee
  // for Algorand there's no ALGO except for the wrapping fee
  const isMainAssetIncludedInUnwrap = mainAssetValue !== "0";

  return (
    <div>
      <p className="my-2 text-sm font-semibold text-white md:my-0">
        <span className="text-gray-200">{selectedNetwork?.unwrapAssetSymbol} (Unwrapped): </span>
        {isMainAssetIncludedInUnwrap && <span>{mainAssetValue} </span>}

        {isERC20Unwrapped ? (
          <span className={`${isMainAssetIncludedInUnwrap ? "text-gray-200" : ""}`}>
            {isMainAssetIncludedInUnwrap
              ? `(${selectedNetwork.unwrappingFee} fee)`
              : `${selectedNetwork.unwrappingFee} fee`}
          </span>
        ) : (
          <span className="text-gray-200">({selectedNetwork.unwrappingFee} fee)</span>
        )}
      </p>
      {isERC20Unwrapped && (
        <p className="my-2 text-sm font-semibold text-white md:my-0">
          <span className="text-gray-200">Token: </span>
          {maValue} {maSymbol}
        </p>
      )}
      {/* <p className="my-2 text-sm font-semibold text-white md:my-0">
        <span className="text-gray-200">Tx ID (Milkomeda): </span>
        <span className="break-all underline">
          <ExternalLink
            href={`${selectedNetwork.milkomedaExplorerBaseURL}/tx/${unwrapItem?.transaction_id}`}
          >
            {unwrapItem?.transaction_id}
          </ExternalLink>
        </span>
      </p>
      <p className="my-2 text-sm font-semibold text-white md:my-0">
        <span className="text-gray-200">Tx ID ({selectedNetwork.mainchain}): </span>
        <span className="break-all underline">
          <ExternalLink
            href={`${
              selectedNetwork.mainchainExplorerBaseURL
            }/${UtilService.getMainchainExplorerTxUrlBase(selectedNetwork.mainchain)}/${
              unwrapItem?.mainchain_tx_id
            }`}
          >
            {unwrapItem?.mainchain_tx_id}
          </ExternalLink>
        </span>
      </p> */}
      <p className="my-2 text-sm font-semibold text-white md:my-0">
        <span className="text-gray-200">Timestamp: </span>
        {unwrapItem?.timestamp
          ? new Date((unwrapItem.timestamp as unknown as number) * 1000).toLocaleString(undefined, {
              dateStyle: "long",
              timeStyle: "long",
            })
          : null}
      </p>
      <p className="my-2 text-sm font-semibold text-white md:my-0">
        <span className="text-gray-200">Confirmation Time: </span>{" "}
        {UtilService.informAboutUnwrappingConfirmation(
          selectedNetwork.mainchain,
          confirmationTimeS,
          unwrapItem?.invalidated
        )}
      </p>
      <div className="mt-2 flex flex-col items-start break-all md:mt-4 md:flex-row md:items-center">
        <div className="flex md:flex-1 md:basis-full">
          <img className="mr-4 w-8 min-w-[2rem]" src={Milkicon} alt="Milkomeda" />
          <div>
            <p className="my-2 text-sm font-semibold text-white md:my-0">
              <span className="text-gray-200">From</span>
            </p>
            <p className="my-2 text-sm font-semibold text-white md:my-0">
              <span className="break-all underline">
                <ExternalLink
                  href={`${selectedNetwork.milkomedaExplorerBaseURL}/tx/${unwrapItem?.transaction_id}`}
                >
                  {unwrapItem?.from}
                </ExternalLink>
                {/* <ExternalLink
                  href={`${selectedNetwork.milkomedaExplorerBaseURL}/address/${unwrapItem?.from}`}
                >
                  {unwrapItem?.from}
                </ExternalLink> */}
              </span>
            </p>
          </div>
        </div>
        <div className="hidden md:block md:min-w-[60px] md:pb-0">
          <img className="mx-auto" src={Arrow} alt="" />
        </div>
        <div className="flex md:flex-1 md:basis-full">
          <img
            className="mr-4 w-8 min-w-[2rem]"
            src={selectedNetwork.iconCard}
            alt={selectedNetwork.mainchain}
          />
          <div>
            <p className="my-2 text-sm font-semibold text-gray-200 md:my-0">
              <span>To</span>
            </p>
            <p className="my-2 text-sm font-semibold text-white md:my-0">
              <span className="break-all underline">
                <ExternalLink
                  href={`${
                    selectedNetwork.mainchainExplorerBaseURL
                  }/${UtilService.getMainchainExplorerTxUrlBase(selectedNetwork.mainchain)}/${
                    unwrapItem?.mainchain_tx_id
                  }`}
                >
                  {unwrapItem?.to}
                </ExternalLink>
                {/* <ExternalLink
                  href={`${selectedNetwork.mainchainExplorerBaseURL}/address/${unwrapItem?.to}`}
                >
                  {unwrapItem?.to}
                </ExternalLink> */}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

function TransactionData({ requestItem, requestType }: TransactionDataProps) {
  if (requestType === RequestType.WRAP) {
    return <WrapData data={requestItem} />;
  } else {
    return <UnwrapData data={requestItem} />;
  }
}

export default TransactionData;
