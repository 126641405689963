import { AssetElement } from "../BridgeService";
import { EvmDetails, Network } from "../ConfigService";
import AlgorandService from "../Mainchains/AlgorandService";
import TokensService from "../TokensService";

declare global {
  interface Window {
    ethereum: any;
    web3: any;
  }
}

export class Evm {
  private static async getAssetSymbol(
    asset: AssetElement,
    selectedNetwork: Network
  ): Promise<string> {
    switch (selectedNetwork.mainchain) {
      case "Algorand":
        return await AlgorandService.getAssetSymbolFromAlgorand(
          TokensService.getAssetId(selectedNetwork.mainchain, asset),
          selectedNetwork
        );
      default:
        return asset.symbol;
    }
  }

  public static async handleAddAssetToMetamask(
    asset: AssetElement,
    selectedNetwork: Network
  ): Promise<void> {
    try {
      if (window && window.ethereum !== undefined) {
        const symbol = await Evm.getAssetSymbol(asset, selectedNetwork);
        // check if asset is already watched in metamask
        await window.ethereum.request({
          method: "wallet_watchAsset",
          params: {
            type: "ERC20",
            options: {
              address: asset.token_contract,
              symbol: symbol,
              decimals: asset.sidechain_decimals ?? 18,
              image: undefined, // let's keep undefined for now since we don't have any common source for images for A1 and C1
            },
          },
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  public static switchEthChain = async (evmDetails: EvmDetails) => {
    try {
      // check if the chain to connect to is installed
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: evmDetails.chainId }],
      });
    } catch (error: any) {
      // 4902 tells that the chain has not yet been added to the Metamask Wallet
      if (error.code === 4902) {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: evmDetails.chainId,
                rpcUrls: [evmDetails.rpcUrl],
                chainName: evmDetails.name,
                nativeCurrency: {
                  name: evmDetails.unitName,
                  symbol: evmDetails.shortUnitName,
                  decimals: evmDetails.decimals,
                },
              },
            ],
          });
        } catch (addError) {
          console.error(addError);
        }
      }
      console.error(error);
    }
  };
}
