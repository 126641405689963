import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { SearchInputType } from "../../Types";
import {
  getRequestsAsync,
  getStatisticsAsync,
  BridgeRequestResponse,
} from "../../Services/BridgeService";
import { BoxColor, MILKOMEDA_BLOCK_TIME_SECONDS } from "../../Constants";
import { detectRequestType, RequestType, UtilService } from "../../Services/UtilService";
import BoxStats from "../../Components/UI/Atoms/BoxStats/BoxStats";
import TitleContainer from "../../Components/UI/Atoms/TitleContainer/TitleContainer";
import TransactionCard from "../../Components/UI/Molecules/TransactionCard/TransactionCard";
import iconclock from "../../Assets/icon_clock.svg";
import IconWrapUnwrap from "../../Assets/icon_wrap_unwrap.svg";
import IconWrap from "../../Assets/icon_wrap.svg";
import IconUnwrap from "../../Assets/icon_unwrap.svg";
import BigNumber from "bignumber.js";
import { generateQueryParams, secondsToMinutesAndSeconds } from "../../utils";
import Loader from "../../Components/UI/Atoms/Loader";
import PullToRefresh from "react-simple-pull-to-refresh";
import { useAppProvider } from "../../context/AppContext";

const MAX_RECENT_RESULTS = 10;

function Home() {
  const { selectedNetwork } = useAppProvider();

  const navigate = useNavigate();
  const [value, setValue] = useState<string>("");
  // TODO: Refactor, it might unnecessary to create a new state based on a react query request
  const [renderedRequests, setRenderedRequests] = useState<BridgeRequestResponse[] | undefined>();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [nextRequestsToRender, setNextRequestsToRender] = useState<
    BridgeRequestResponse[] | undefined
  >();

  const {
    isLoading: isBridgeStatsLoading,
    isSuccess: isBridgeStatsSuccess,
    data: bridgeStats,
    refetch: refetchBridgeStats,
  } = useQuery(
    "bridgeStats",
    async () => await getStatisticsAsync(selectedNetwork.bridgeApiBaseURL),
    {
      refetchInterval: MILKOMEDA_BLOCK_TIME_SECONDS,
    }
  );

  const {
    isLoading: isRecentRequestsLoading,
    isSuccess: isRecentRequestsSuccess,
    data: recentRequests,
    refetch: refetchRecentRequests,
  } = useQuery(
    ["recentRequests"],
    async () => {
      const response = await getRequestsAsync({
        baseUrl: selectedNetwork.bridgeApiBaseURL,
        limit: MAX_RECENT_RESULTS,
      });

      if (!renderedRequests || renderedRequests?.length === 0) {
        return response.requests;
      }
      // if the first request from both arrays is different, then there's new request to show
      if (
        renderedRequests &&
        response.requests[0].assets[0].request_id !== renderedRequests[0].assets[0].request_id
      ) {
        return response.requests;
      }
      return [];
    },
    {
      refetchInterval: MILKOMEDA_BLOCK_TIME_SECONDS,
    }
  );

  const onSearchClicked = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const valueTrimmed = value.trim();
    if (!valueTrimmed) return;
    const queryParams = generateQueryParams({
      query: valueTrimmed,
    });
    // Check if search value is EVM Transaction ID
    if (UtilService.isEvmTxIdAsync(valueTrimmed)) {
      navigate({
        pathname: `/${selectedNetwork.id}/search/${SearchInputType.MilkomedaTxId}`,
        search: queryParams,
      });
    } else {
      // if not evm, then it has to be mainchain or it's incorrect
      navigate({
        pathname: `/${selectedNetwork.id}/search/${SearchInputType.MainchainTxId}`,
        search: queryParams,
      });
    }
  };

  // process the results and render them
  useEffect(() => {
    if (renderedRequests == null) {
      setRenderedRequests(recentRequests ?? []);
    } else if (recentRequests && recentRequests?.length > 0) {
      let newRequestItems = recentRequests.filter(
        (i) =>
          renderedRequests.find((j) => j.assets[0].request_id === i.assets[0].request_id) == null
      );

      // TODO: remove once Algorand indexer is fixed with the newest queries
      if (selectedNetwork.mainchain === "Algorand") {
        newRequestItems = UtilService.adjustRequestsList(newRequestItems);
      }
      const newRenderedRequests = [...newRequestItems, ...renderedRequests];
      setRenderedRequests(newRenderedRequests.slice(0, MAX_RECENT_RESULTS));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recentRequests, nextRequestsToRender]);

  const getTotalBridgeTxCount = () =>
    isBridgeStatsSuccess ? bridgeStats.total_wraps + bridgeStats.total_unwraps : "-";
  const getTotalDayTxCount = () =>
    isBridgeStatsSuccess ? bridgeStats.last_24h_wraps + bridgeStats.last_24h_unwraps : "-";

  // todo: cleanup
  let totalWmainLocked = isBridgeStatsSuccess
    ? new BigNumber(bridgeStats.total_ada_locked)
        .div(new BigNumber("1000000"))
        .toNumber()
        .toLocaleString()
    : "-";

  return (
    <PullToRefresh
      onRefresh={async () => {
        await refetchBridgeStats();
        await refetchRecentRequests();
      }}
      refreshingContent={
        <div className="flex w-full justify-center pt-28 ">
          <Loader className="h-5 text-white" />
        </div>
      }
    >
      <div className="mx-auto flex w-full max-w-7xl flex-wrap px-5 py-9 pt-24">
        <div className="grid w-full grid-cols-cardLayout gap-4">
          <BoxStats
            isLoading={isBridgeStatsLoading}
            text="Total Bridge Txs"
            value={getTotalBridgeTxCount().toLocaleString()}
            img={<img src={IconWrapUnwrap} alt="bridge-icon" />}
          />
          <BoxStats
            isLoading={isBridgeStatsLoading}
            text="Last 24h Bridge txs"
            value={getTotalDayTxCount().toLocaleString()}
            img={<img src={IconWrapUnwrap} alt="bridge-icon" />}
          />
          <BoxStats
            isLoading={isBridgeStatsLoading}
            text={`Total ${selectedNetwork?.wrapAssetSymbol} Locked`}
            value={totalWmainLocked}
          />
          <BoxStats
            isLoading={isBridgeStatsLoading}
            text="Total Wraps"
            value={isBridgeStatsSuccess ? bridgeStats?.total_wraps.toLocaleString() : "-"}
            img={<img src={IconWrap} alt="wrap-icon" />}
          />
          <BoxStats
            isLoading={isBridgeStatsLoading}
            text="Last 24h Wraps"
            value={isBridgeStatsSuccess ? bridgeStats?.last_24h_wraps.toLocaleString() : "-"}
            img={<img src={IconWrap} alt="wrap-icon" />}
          />
          <BoxStats
            isLoading={isBridgeStatsLoading}
            text="AVG Wrapping time [24h]"
            value={
              isBridgeStatsSuccess
                ? `${secondsToMinutesAndSeconds(parseInt(bridgeStats?.avg_24h_wrap_in_sec ?? "0"))}`
                : "-"
            }
            img={<img src={iconclock} alt="clock-icon" />}
            color={BoxColor.PRIMARY}
          />
          <BoxStats
            isLoading={isBridgeStatsLoading}
            text="Total Unwraps"
            value={isBridgeStatsSuccess ? bridgeStats?.total_unwraps.toLocaleString() : "-"}
            img={<img src={IconUnwrap} alt="unwrap-icon" />}
          />
          <BoxStats
            isLoading={isBridgeStatsLoading}
            text="Last 24h Unwraps"
            value={isBridgeStatsSuccess ? bridgeStats?.last_24h_unwraps.toLocaleString() : "-"}
            img={<img src={IconUnwrap} alt="unwrap-icon" />}
          />
          <BoxStats
            isLoading={isBridgeStatsLoading}
            text="AVG Unwrapping time [24h]"
            value={
              isBridgeStatsSuccess
                ? `${secondsToMinutesAndSeconds(
                    parseInt(bridgeStats?.avg_24h_unwrap_in_sec ?? "0")
                  )}`
                : "-"
            }
            img={<img src={iconclock} alt="clock-icon" />}
            color={BoxColor.PRIMARY}
          />
        </div>
        <div className="mx-1 my-6 flex w-full flex-wrap content-between">
          <TitleContainer
            text="Last 10 Transactions"
            onSearchClicked={onSearchClicked}
            value={value}
            setValue={setValue}
          />
          {isRecentRequestsLoading ? (
            <div className="mt-5 flex flex-1 items-center justify-center md:mt-10 md:h-9">
              <Loader className="text-orange-600 md:h-9" />
            </div>
          ) : null}
          {isRecentRequestsSuccess &&
            renderedRequests != null &&
            renderedRequests.map((requestItem) => {
              let requestType = RequestType.WRAP;
              if (
                detectRequestType(requestItem?.assets[0].request_id ?? "") === RequestType.UNWRAP
              ) {
                requestType = RequestType.UNWRAP;
              }
              return (
                <button
                  className="my-4 w-full text-left"
                  key={`${requestItem.mainchain_tx_id}-${requestItem.transaction_id}`}
                  onClick={() =>
                    navigate(
                      requestType === RequestType.WRAP
                        ? `/${selectedNetwork.id}/wrap/${requestItem.mainchain_tx_id}`
                        : `/${selectedNetwork.id}/unwrap/${requestItem.assets?.[0]?.request_id}`,
                      { state: { fromPathname: "/" } }
                    )
                  }
                >
                  <TransactionCard requestItem={requestItem} requestType={requestType} />
                </button>
              );
            })}
        </div>
      </div>
    </PullToRefresh>
  );
}

export default Home;
