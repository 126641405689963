import Cip5 from "@dcspark/cip5-js";
import { bech32 } from "bech32";
import BigNumber from "bignumber.js";
import { ethers } from "ethers";
import { MAIN_ASSET_ID } from "../Constants";
import { BridgeRequestResponse, BridgeWrapAssets } from "./BridgeService";
import moment from "moment";
import { Network, Networks, networksMap } from "./ConfigService";

export type AssetDataMapItem = {
  asset_id: string;
  decimals: number;
  symbol: string;
};

export enum RequestType {
  WRAP = "wrap",
  UNWRAP = "unwrap",
}

export const detectRequestType = (requestId: string): RequestType => {
  try {
    if (Number(requestId)) {
      return RequestType.UNWRAP;
    }
    return RequestType.WRAP;
  } catch (e) {
    console.log(`${requestId} is ${RequestType.UNWRAP}`);
    return RequestType.WRAP;
  }
};

class UtilService {
  public static informAboutUnwrappingConfirmation = (
    mainchain: string,
    confirmationTime: number | null,
    invalidated?: boolean
  ) => {
    if (invalidated !== undefined && invalidated === true) {
      return "Request failed to be processed.";
    }

    return confirmationTime !== null
      ? this.formatTime(confirmationTime)
      : `awaiting confirmation on ${mainchain} side`;
  };

  public static informAboutWrappingConfirmation = (
    confirmationTime: number | null,
    invalidated?: boolean
  ) => {
    if (invalidated !== undefined && invalidated === true) {
      return "Request failed to be processed.";
    }

    return confirmationTime !== null
      ? this.formatTime(confirmationTime)
      : "awaiting confirmation on EVM side";
  };

  public static formatTime(confirmationTime: number): string {
    return `${moment.unix(confirmationTime).format("mm")} min ${moment
      .unix(confirmationTime)
      .format("ss")} s`;
  }

  public static timestampToDateTime(timestamp: number): string {
    return new Date(timestamp * 1000).toLocaleString(undefined, {
      dateStyle: "long",
      timeStyle: "long",
    });
  }

  // each mainchain explorer has its own transaction base, the ones used for cardano and algorand are different, hence mapping is required
  public static getMainchainExplorerTxUrlBase(mainchainName: string): string {
    if (mainchainName === networksMap[Networks.algorandDevnet].mainchain) {
      return "tx";
    }
    if (mainchainName === networksMap[Networks.cardanoDevnet].mainchain) {
      return "tx";
    }
    return "transaction";
  }

  public static getMainchainExplorerAssetUrlBase(mainchainName: string): string {
    let urlBase = "";
    switch (mainchainName) {
      case "Algorand":
        urlBase = "asset";
        break;
      case "Cardano":
        urlBase = "asset";
        break;
      default:
        break;
    }

    return urlBase;
  }

  // public static async getAssetDataMapAsync(assetIds: string[]) {
  //   const assets = await BridgeService.getAssetsAsync();
  //   return assets.reduce<Promise<Record<string, AssetDataMapItem>>>(async (accP, asset) => {
  //     const acc = await accP;
  //     if (assetIds.find((id) => id === asset.asset_id) != null && acc[asset.asset_id] == null) {
  //       const symbol = await MilkomedaService.getTokenNameAsync(asset.token_contract);
  //       const decimals = await MilkomedaService.getTokenDecimalAsync(asset.token_contract);
  //       if (symbol != null && decimals != null) {
  //         acc[asset.asset_id] = {
  //           asset_id: asset.asset_id,
  //           symbol,
  //           decimals,
  //         };
  //       }
  //     }
  //     return acc;
  //   }, Promise.resolve({}));
  // }

  public static formatCompactNumber(value: string): string | number {
    try {
      const formatter = Intl.NumberFormat("en", { notation: "compact" });
      return formatter.format(value as unknown as number);
    } catch (err) {
      const error = err as Error;
      console.error(error.message);
      return value;
    }
  }

  public static formatValue(
    value: string,
    assetId: string,
    assetDataMap?: Record<string, AssetDataMapItem>,
    mainDecimals?: number,
    mainchainName?: string
  ) {
    let assetAmount = "0";
    if (assetId === MAIN_ASSET_ID) {
      assetAmount = this.decimalsToMainUnitConversion(value, mainDecimals);

      // Algorand ALGO transfers include the fee which has to be substracted to show how much was transferred to the account and how much was the wrapping fee
      if (
        mainchainName !== undefined &&
        mainchainName === networksMap[Networks.algorandDevnet].name &&
        assetAmount !== "0"
      ) {
        const amount = new BigNumber(assetAmount).minus(
          new BigNumber(networksMap[Networks.algorandDevnet].wrappingFee)
        );

        return amount.toString() ?? "0";
      }
      return assetAmount;
    }

    try {
      if (assetDataMap != null && assetDataMap[assetId] != null)
        return ethers.utils.formatUnits(value, assetDataMap[assetId].decimals);
      else return value;
    } catch (e) {
      return value;
    }
  }

  public static decimalsToMainUnitConversion(mainValue: string, mainDecimals?: number): string {
    if (mainDecimals === 0) return mainValue;
    if (mainDecimals !== undefined) {
      return new BigNumber(mainValue).div(new BigNumber("10").pow(mainDecimals)).toString();
    }
    return new BigNumber(mainValue).div(new BigNumber("1000000000000000000")).toString();
  }

  public static isEvmTxIdAsync = (txId: string) => {
    // try basic regex, to just validate if tx is valid evm tx id
    return /^0x([A-Fa-f0-9]{64})$/.test(txId);
  };

  public static isCardanoAddress = (address: string) => {
    try {
      // Not sure what should be the limit for Cardano Addresses?
      // Also not sure if I should use serialization-lib vs just bech32 decoding
      // for detecing if its a valid cardano address
      const bech32Info = bech32.decode(address, 1000);
      if (bech32Info.prefix === Cip5.miscellaneous.addr) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  };

  // todo: resolve problem on the backend, but handle in app for the time being
  public static adjustRequestsList = (
    wrapItems: BridgeRequestResponse[]
  ): BridgeRequestResponse[] => {
    if (wrapItems.length > 0) {
      let results: BridgeRequestResponse[] = [];
      for (let item of wrapItems) {
        // check if request with the given mainchain transaction id was already added to results
        let alreadyAdded = results.find(
          (request) => request.mainchain_tx_id === item.mainchain_tx_id
        );
        let includeInArr = true;
        // if there are more than 1 asset, check if one or the other is not wmain and doesn't have a symbol
        if (item.assets.length > 1) {
          includeInArr =
            (item.assets[1].asset_id !== MAIN_ASSET_ID && item.assets[1].symbol !== "") ||
            (item.assets[0].asset_id !== MAIN_ASSET_ID && item.assets[0].symbol !== "");
        }
        if (!alreadyAdded && includeInArr) {
          results.push(item);
        }
      }
      return results;
    }
    return [];
  };

  public static truncateString = (value: string): string => {
    return `${value.substring(0, 40)}..${value.substring(value.length - 12, value.length)}`;
  };

  public static isValidStr = (value: string | undefined | null): boolean => {
    return !(!value || value.length === 0);
  };

  public static calculateAssetValue = (
    asset: BridgeWrapAssets,
    selectedNetwork: Network
  ): string => {
    if (asset?.details?.asset_id !== MAIN_ASSET_ID) {
      return ethers.utils.formatUnits(
        asset?.details?.asset_value ?? "0",
        asset?.details?.mainchain_decimals ?? 0
      );
    } else if (selectedNetwork.mainchain === "Algorand") {
      return UtilService.formatValue(
        asset?.details?.asset_value,
        MAIN_ASSET_ID,
        undefined,
        selectedNetwork.mainAssetDecimals
      );
    } else if (selectedNetwork.mainchain === "Cardano") {
      return UtilService.formatValue(asset?.details?.asset_value, MAIN_ASSET_ID, undefined, 6);
    } else {
      return asset?.details?.asset_value;
    }
  };
}

export { UtilService };
