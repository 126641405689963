// import Config from "../Config";
import { TxStatus } from "../Types";
import { generateUrlWithQueryParams } from "../utils";

export type AssetElement = {
  asset_id: string;
  token_contract: string;
  token_type: string;
  minimum_value: string;
  timestamp: number;
  sidechain_decimals?: number;
  mainchain_decimals?: number;
  symbol: string;
  cardano_policy?: any;
  cardano_fingerprint?: any;
  tvl?: number;
};

export type AssetWrapDetails = {
  asset_id: string;
  asset_value: string;
  cardano_fingerprint?: string;
  cardano_policy?: string;
  mainchain_decimals?: string;
  request_id: string;
  sidechain_decimals?: string;
  symbol?: string;
};

export type BridgeWrapAssets = {
  request_id: string;
  mainchain_tx_id: string;
  asset_id: string;
  asset_value: string;
  details: AssetWrapDetails;
  transaction_id: string;
  executed_state: boolean;
  executed_block_number: string;
  executed_timestamp: number;
  votes: {
    request_id: string;
    voter: string;
    block_number: number;
    block_id: string;
    transaction_id: string;

    // optional since not all versions of the indexer are upgraded yet
    timestamp?: number;
  }[];
  invalidated: boolean;
};

export type BridgeWrapResponse = {
  wrapping_details: {
    mainchain_tx_id: string;
    to: string;
    block_number: number;
    block_id: string;
    stargate_address: string;
    protocol_magic: string;
    block_timestamp: number;
  };
  wrapping_assets: BridgeWrapAssets[];
};

export type AssetUnwrapDetails = {
  asset_id: string;
  asset_value: string;
  cardano_fingerprint: string | null;
  cardano_policy: string | null;
  mainchain_decimals: number | null;
  request_id: string;
  sidechain_decimals: number | null;
  symbol: string | null;
};

export type BridgeUnwrapResponse = {
  unwrapping_details: {
    request_id: string;
    block_number: number;
    block_id: string;
    from: string;
    to: string;
    transaction_id: string;
    block_timestamp: number;
    invalidated: boolean;
  };
  unwrapping_votes: [
    {
      id: number;
      request_id: string;
      voter: string;
      witness: string;
      block_number: number;
      block_id: string;
      transaction_id: string;
      timestamp?: number;
    }
  ];
  unwrapping_transaction: {
    request_id: string;
    mainchain_tx_id: string;
    raw_transaction: string;
    creator: string;
    block_number: number;
    block_id: string;
    confirmed_block_number: number;
    executed_block_number: number;
    transaction_id: string;
    executed_timestamp: number | null;
  };
  unwrapping_assets: AssetUnwrapDetails[];
};

export type BridgeUnwrappingsResponse = {
  request_id: string;
  mainchain_tx_id: string;
  transaction_id: string;
  sidechain_address: string;
  mainchain_address: string;
  creator: string;
  block_number: number;
  block_id: string;
  confirmed_block_number: number | null;
  executed_block_number: number | null;
  confirmed_block_id: string | null;
  executed_block_id: string | null;
  assets: [
    {
      request_id: string;
      asset_id: string;
      asset_value: string;
    }
  ];
};

export type BridgeWrappingResponse = {
  row_id: number;
  mainchain_tx_id: string;
  to: string;
  block_number: number;
  block_id: string;
  stargate_address: string;
  protocol_magic: string;
  block_timestamp: number;
  assets: [
    {
      request_id: string;
      mainchain_tx_id: string;
      asset_id: string;
      asset_value: string;
      executed_state?: boolean;
      executed_block_number?: string;
      executed_timestamp?: number;
      transaction_id: string;
    }
  ];
};

export type BridgeAssetResponse = {
  asset_id: string;
  token_contract: string;
  token_type: string;
  minimum_value: string;
};

export type BridgeStatistics = {
  avg_24h_wrap_in_sec: string;
  avg_24h_unwrap_in_sec: string;
  last_24h_wraps: number;
  last_24h_unwraps: number;
  total_wraps: number;
  total_unwraps: number;
  total_ada_locked: number;
};

// /requests
export type BridgeRequestResponse = {
  transaction_id: string;
  mainchain_tx_id: string;
  from: string;
  to: string;
  block_number: number;
  block_id: string;
  timestamp: string;
  executed_timestamp?: number | null;
  assets: Array<BridgeRequestAssetDetails>;
  invalidated: boolean;
};

export type BridgeRequestAssetDetails = {
  asset_id: string;
  asset_value: string;
  request_id: string;
  symbol: string | null;
  mainchain_decimals: number | null;
  sidechain_decimals: number | null;
  cardano_policy: string | null;
  cardano_fingerprint: string | null;
};

export type BridgeRequestsResponse = {
  requests: Array<BridgeRequestResponse>;
  following_params: BridgeRequestFollowingParams;
};

export type BridgeRequestFollowingParams = {
  next_wrap_id: number;
  next_unwrap_id: number;
};

/** Endpoints start **/

export const getRequestsAsync = async ({
  baseUrl,
  wrap_id,
  unwrap_id,
  limit,
  sort = "Desc",
  txStatus,
}: {
  baseUrl: string;
  wrap_id?: number | null;
  unwrap_id?: number | null;
  limit?: number | null;
  sort?: "Asc" | "Desc";
  txStatus?: TxStatus;
}): Promise<BridgeRequestsResponse> => {
  const url = generateUrlWithQueryParams(`${baseUrl}/requests`, {
    sort,
    wrap_id: wrap_id,
    unwrap_id: unwrap_id,
    count: limit,
    request_status: txStatus === TxStatus.All ? null : txStatus,
  });
  const request = await fetch(url);
  return await request.json();
};

export const getStatisticsAsync = async (baseUrl: string): Promise<BridgeStatistics> => {
  const request = await fetch(`${baseUrl}/statistics`);
  return await request.json();
};
export const getWrappingRequestIdDetails = async ({
  baseUrl,
  mainchainTxId,
}: {
  baseUrl: string;
  mainchainTxId?: string;
}): Promise<BridgeWrapResponse> => {
  const url = generateUrlWithQueryParams(`${baseUrl}/wrapping_requests`, {
    mainchain_tx_id: mainchainTxId,
  });
  const request = await fetch(url);
  return await request.json();
};
export const getUnwrappingRequestIdDetails = async ({
  baseUrl,
  requestId,
}: {
  baseUrl: string;
  requestId?: string;
}): Promise<BridgeUnwrapResponse> => {
  const url = generateUrlWithQueryParams(`${baseUrl}/unwrapping_requests`, {
    request_id: requestId,
  });
  const request = await fetch(url);
  return await request.json();
};

export const getRequestsByMainchainAddressAsync = async ({
  baseUrl,
  address,
  limit = 10,
  txStatus,
}: {
  baseUrl: string;
  address: string;
  limit: number;
  txStatus?: TxStatus;
}): Promise<BridgeRequestsResponse> => {
  const url = generateUrlWithQueryParams(`${baseUrl}/requests`, {
    to: address,
    count: limit,
    request_status: txStatus === TxStatus.All ? null : txStatus,
  });
  const request = await fetch(url);
  return await request.json();
};

export const getRequestsByMainchainTxIdAsync = async ({
  baseUrl,
  mainchainTxId,
  limit = 10,
  txStatus,
}: {
  baseUrl: string;
  mainchainTxId: string;
  limit: number;
  txStatus?: TxStatus;
}): Promise<BridgeRequestsResponse> => {
  const url = generateUrlWithQueryParams(`${baseUrl}/requests`, {
    mainchain_tx_id: mainchainTxId,
    count: limit,
    request_status: txStatus === TxStatus.All ? null : txStatus,
  });
  const request = await fetch(url);
  return await request.json();
};

export const getRequestsByMilkomedaAddressAsync = async ({
  baseUrl,
  limit = 10,
  evmAddress,
}: {
  baseUrl: string;
  limit: number;
  evmAddress?: string;
}): Promise<BridgeRequestsResponse> => {
  const url = generateUrlWithQueryParams(`${baseUrl}/requests`, {
    count: limit,
    evm_address: evmAddress,
  });
  const request = await fetch(url);
  return await request.json();
};

export const getRequestsByMilkomedaTxIdAsync = async ({
  baseUrl,
  milkomedaTxId,
  limit = 10,
  txStatus,
}: {
  baseUrl: string;
  milkomedaTxId: string;
  limit: number;
  txStatus?: TxStatus;
}): Promise<BridgeRequestsResponse> => {
  const url = generateUrlWithQueryParams(`${baseUrl}/requests`, {
    tx_id: milkomedaTxId,
    count: limit,
    request_status: txStatus === TxStatus.All ? null : txStatus,
  });
  const request = await fetch(url);
  return await request.json();
};
/** Endpoints end **/

class BridgeService {
  public static async getAssetsAsync(baseUrl: string): Promise<Array<BridgeAssetResponse>> {
    const request = await fetch(`${baseUrl}/assets`);
    return await request.json();
  }

  /** Unwrapping Endpoints **/
  public static async getUnwrappingsByMainchainAddressAsync(
    baseUrl: string,
    address: string,
    offset?: number,
    limit: number = 10
  ): Promise<Array<BridgeUnwrappingsResponse>> {
    const url = generateUrlWithQueryParams(`${baseUrl}/unwrappings`, {
      to: address,
      count: limit,
      start: offset,
    });
    const request = await fetch(url);
    return await request.json();
  }

  public static async getUnwrappingsByMilkomedaAddressAsync(
    baseUrl: string,
    milkomedaAddress: string,
    offset?: number,
    limit: number = 10
  ): Promise<Array<BridgeUnwrappingsResponse>> {
    const url = generateUrlWithQueryParams(`${baseUrl}/unwrappings`, {
      from: milkomedaAddress,
      count: limit,
      start: offset,
    });
    const request = await fetch(url);
    return await request.json();
  }

  public static async getUnwrappingsByMainchainTxIdAsync(
    baseUrl: string,
    mainchainTxId: string,
    offset?: number,
    limit: number = 10
  ): Promise<Array<BridgeUnwrappingsResponse>> {
    const url = generateUrlWithQueryParams(`${baseUrl}/unwrappings`, {
      mainchain_tx_id: mainchainTxId,
      count: limit,
      start: offset,
    });
    const request = await fetch(url);
    return await request.json();
  }

  public static async getUnwrappingsByMilkomedaTxIdAsync(
    baseUrl: string,
    milkomedaTxId: string,
    offset?: number,
    limit: number = 10
  ): Promise<Array<BridgeUnwrappingsResponse>> {
    const url = generateUrlWithQueryParams(`${baseUrl}/unwrappings`, {
      tx_id: milkomedaTxId,
      count: limit,
      start: offset,
    });
    const request = await fetch(url);
    return await request.json();
  }

  public static async getRecentUnwrappingsAsync(
    baseUrl: string,
    offset?: number | null,
    limit?: number | null,
    sort: "Asc" | "Desc" = "Desc"
  ): Promise<Array<BridgeUnwrappingsResponse>> {
    const url = generateUrlWithQueryParams(`${baseUrl}/unwrappings`, {
      sort,
      count: limit,
      start: offset,
    });
    const request = await fetch(url);
    return await request.json();
  }

  /** Wrapping Endpoints **/
  public static async getWrappingsByMilkomedaAddressAsync(
    baseUrl: string,
    milkomedaAddress: string,
    offset?: number,
    limit: number = 10
  ): Promise<Array<BridgeWrappingResponse>> {
    const url = generateUrlWithQueryParams(`${baseUrl}/wrappings`, {
      to: milkomedaAddress,
      count: limit,
      start: offset,
    });
    const request = await fetch(url);
    return await request.json();
  }

  public static async getWrappingsByMainchainTxIdAsync(
    baseUrl: string,
    mainchainTxId: string,
    offset?: number,
    limit: number = 10
  ): Promise<Array<BridgeWrappingResponse>> {
    const url = generateUrlWithQueryParams(`${baseUrl}/wrappings`, {
      mainchain_tx_id: mainchainTxId,
      count: limit,
      start: offset,
    });
    const request = await fetch(url);
    return await request.json();
  }

  public static async getRecentWrappingsAsync(
    baseUrl: string,
    offset?: number | null,
    limit?: number | null,
    sort: "Asc" | "Desc" = "Desc"
  ): Promise<Array<BridgeWrappingResponse>> {
    const url = generateUrlWithQueryParams(`${baseUrl}/wrappings`, {
      sort,
      count: limit,
      start: offset,
    });
    const request = await fetch(url);
    return await request.json();
  }
}

export const getAssetList = async ({
  baseUrl,
  active,
}: {
  baseUrl: string;
  active: boolean;
}): Promise<AssetElement[]> => {
  try {
    const url = generateUrlWithQueryParams(`${baseUrl}/assets`, {
      active,
    });
    const request = await fetch(url);
    return (await request.json()) as AssetElement[];
  } catch (err) {
    console.error(err);
    return [];
  }
};

export { BridgeService };
