import {
  BridgeRequestResponse,
  BridgeUnwrappingsResponse,
  BridgeWrappingResponse,
} from "./Services/BridgeService";

export enum SearchInputType {
  EvmAddress = "evmAddress",
  MainchainAddress = "mainchainAddress",
  MilkomedaTxId = "tx",
  MainchainTxId = "mainchainTx",
}

export type ProcessedBridgeRequestResponse = BridgeRequestResponse & {
  // symbol?: string;
  formatedValue?: string;
};

export type ProcessedBridgeWrappingResponse = BridgeWrappingResponse & {
  symbol?: string;
  formatedValue?: string;
};

export type ProcessedBridgeUnwrappingResponse = BridgeUnwrappingsResponse & {
  symbol?: string;
};

export declare type TokenRegistryEntry = {
  name?: string;
  decimals?: number;
  ticker?: string;
  description?: string;
  url?: string;
  policy: string;
  logo?: string;
};

export declare type TokenRegistryResponse = {
  success: boolean;
  data: Record<string, TokenRegistryEntry>;
  message?: string;
};

export enum TxStatus {
  Failed = "Failed",
  Pending = "Pending",
  Confirmed = "Confirmed",
  All = "All",
}

export enum FilterStatus {
  EvmAddress = "Address",
}

export type LocationState = {
  fromPathname: string;
};
