import { useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../Components/UI/Atoms/Loader";
import { BridgeUnwrapResponse, getUnwrappingRequestIdDetails } from "../Services/BridgeService";

import { ReactComponent as ChevronLeft } from "../Assets/chevron-left.svg";

import NotFound from "../Components/UI/Molecules/NotFound";
import { MAIN_ASSET_ID } from "../Constants";
import { ethers } from "ethers";
import { RequestType, UtilService } from "../Services/UtilService";
import { useAppProvider } from "../context/AppContext";
import { MilkomedaService } from "../Services/MilkomedaService";
import { LocationState, ProcessedBridgeRequestResponse } from "../Types";
import TransactionCard from "../Components/UI/Molecules/TransactionCard/TransactionCard";
import { Networks } from "../Services/ConfigService";
import AlgorandService from "../Services/Mainchains/AlgorandService";
import * as Tabs from "@radix-ui/react-tabs";

const LabelValueRow = ({ label, value, href }: { label: string; value: string; href?: string }) => {
  const valueComponent =
    href != null ? (
      <dl className="break-all text-white">
        <a
          className="break-all text-white underline transition duration-300 ease-out hover:text-sky-400"
          href={href}
          target="_blank"
          rel="noreferrer noopener"
        >
          {value}
        </a>
      </dl>
    ) : (
      <dl className="break-all text-white">{value}</dl>
    );

  return (
    <div className="my-2 flex flex-col text-sm font-semibold md:flex-row">
      <dt className="min-w-[10rem] basis-0 text-gray-200 md:basis-40">{label}:</dt>
      {valueComponent}
    </div>
  );
};

export default function UnwrapTx() {
  const { requestId } = useParams();
  const { selectedNetwork } = useAppProvider();

  const navigate = useNavigate();
  const location = useLocation();
  const { fromPathname } = (location.state as LocationState) ?? { fromPathname: null };

  const unwrappingRequest = useQuery<BridgeUnwrapResponse>(
    ["unwrapping-request", requestId],
    () => {
      return getUnwrappingRequestIdDetails({
        baseUrl: selectedNetwork.bridgeApiBaseURL,
        requestId,
      });
    },
    { enabled: !!requestId }
  );

  const executionState = MilkomedaService.getStatusOfRequestedItem(
    unwrappingRequest.data?.unwrapping_transaction?.executed_timestamp ?? null,
    undefined
  );

  const showFeeNotIncludedInAssetAmount =
    unwrappingRequest.data?.unwrapping_assets.length === 1 ? " + 1.0 (FEE)" : null;

  const formatUnwrappingResponse = (
    item: BridgeUnwrapResponse
  ): ProcessedBridgeRequestResponse => ({
    transaction_id: item.unwrapping_transaction?.transaction_id,
    mainchain_tx_id: item.unwrapping_transaction?.mainchain_tx_id,
    from: item.unwrapping_details?.from,
    to: item.unwrapping_details?.to,
    block_id: item.unwrapping_details?.block_id,
    block_number: item.unwrapping_details?.block_number,
    timestamp: item.unwrapping_details?.block_timestamp.toString(),
    executed_timestamp: item.unwrapping_transaction?.executed_timestamp,
    invalidated: item.unwrapping_details?.invalidated,
    assets: item.unwrapping_assets,
  });

  return (
    <div className="position mx-auto flex w-full max-w-7xl flex-col px-5 py-9 pt-24">
      <button
        onClick={() => {
          if (fromPathname) {
            navigate(`/${selectedNetwork.id}${fromPathname}`);
            return;
          }
          navigate(`/${selectedNetwork.id}`);
        }}
        className="flex items-center"
      >
        <ChevronLeft className="mr-1" />
        <p className="text-sm text-gray-200">Back</p>
      </button>
      <h1 className="mt-3 mb-4 text-lg font-bold text-white">Transaction Details</h1>
      {unwrappingRequest.isLoading ? (
        <Loader className="mt-5 flex h-6 w-full justify-center text-white md:mt-10 md:h-9" />
      ) : unwrappingRequest.isSuccess && unwrappingRequest.data == null ? (
        <NotFound />
      ) : (
        unwrappingRequest.data && (
          <>
            <TransactionCard
              requestType={RequestType.UNWRAP}
              requestItem={formatUnwrappingResponse(unwrappingRequest.data)}
            />

            <div className="animate-fadeIn text-white transition-all duration-100">
              <Tabs.Root defaultValue="details" className="detail-section">
                <Tabs.List className="tab-list">
                  <Tabs.Trigger value="details" className="tab">
                    Unwrapping request
                  </Tabs.Trigger>
                  <Tabs.Trigger value="assets" className="tab">
                    Assets
                  </Tabs.Trigger>
                  <Tabs.Trigger value="votes" className="tab">
                    Votes
                  </Tabs.Trigger>
                </Tabs.List>
                <Tabs.Content className="tab-content" value="details">
                  <dl key={unwrappingRequest.data.unwrapping_details.request_id}>
                    <LabelValueRow label="Status" value={executionState} />
                    <LabelValueRow
                      label="Request ID"
                      value={unwrappingRequest.data.unwrapping_details.request_id}
                    />
                    <LabelValueRow
                      label="Milkomeda Tx ID"
                      href={`${selectedNetwork.milkomedaExplorerBaseURL}/tx/${unwrappingRequest.data.unwrapping_details.transaction_id}`}
                      value={unwrappingRequest.data.unwrapping_details.transaction_id}
                    />
                    <LabelValueRow
                      label={`${selectedNetwork.mainchain} Tx ID`}
                      href={`${
                        selectedNetwork.mainchainExplorerBaseURL
                      }/${UtilService.getMainchainExplorerTxUrlBase(selectedNetwork.mainchain)}/${
                        unwrappingRequest.data.unwrapping_transaction?.mainchain_tx_id
                      }`}
                      value={unwrappingRequest.data.unwrapping_transaction?.mainchain_tx_id}
                    />
                    <LabelValueRow
                      label="From"
                      href={`${selectedNetwork.milkomedaExplorerBaseURL}/address/${unwrappingRequest.data.unwrapping_details.from}`}
                      value={unwrappingRequest.data.unwrapping_details.from}
                    />
                    <LabelValueRow
                      label="To"
                      href={`${selectedNetwork.mainchainExplorerBaseURL}/address/${unwrappingRequest.data.unwrapping_details.to}`}
                      value={unwrappingRequest.data.unwrapping_details.to}
                    />
                    <LabelValueRow
                      label="Block Number"
                      value={unwrappingRequest.data.unwrapping_details.block_number.toString()}
                    />
                    <LabelValueRow
                      label="Block ID"
                      value={unwrappingRequest.data.unwrapping_details.block_id}
                    />
                  </dl>
                </Tabs.Content>
                <Tabs.Content className="tab-content" value="assets">
                  <div className="space-y-4 divide-y-[1px] divide-[#383534]">
                    {unwrappingRequest.data.unwrapping_assets.length > 0 ? (
                      unwrappingRequest.data.unwrapping_assets.map((asset) => {
                        const symbol = asset?.symbol ?? selectedNetwork?.wrapAssetSymbol;
                        const assetSymbol =
                          selectedNetwork.id === Networks.algorandMainnet
                            ? AlgorandService.extractSymbolFromName(symbol)
                            : symbol;

                        const assetValue =
                          asset?.asset_id !== MAIN_ASSET_ID
                            ? ethers.utils.formatUnits(
                                asset?.asset_value ?? "0",
                                asset?.sidechain_decimals ?? 0
                              )
                            : UtilService.decimalsToMainUnitConversion(asset?.asset_value);

                        return (
                          <dl key={asset.asset_id} className="">
                            <LabelValueRow label="Asset ID" value={asset.asset_id} />
                            <LabelValueRow
                              label="Asset Value"
                              value={`${assetValue === "0" ? "1" : assetValue} ${assetSymbol} ${
                                showFeeNotIncludedInAssetAmount ?? ""
                              }`}
                            />
                          </dl>
                        );
                      })
                    ) : (
                      <p className="mt-3 text-sm text-white">No assets found</p>
                    )}
                  </div>
                </Tabs.Content>
                <Tabs.Content className="tab-content" value="votes">
                  <div className="space-y-6 divide-y-[1px] divide-[#383534]">
                    {unwrappingRequest.data.unwrapping_votes.length > 0 ? (
                      unwrappingRequest.data.unwrapping_votes.map((vote) => (
                        <dl className="pt-5 first-of-type:pt-0" key={vote.voter}>
                          <LabelValueRow label="Voter" value={vote.voter} />
                          {vote.timestamp && (
                            <LabelValueRow
                              label="Timestamp"
                              value={UtilService.timestampToDateTime(vote.timestamp)}
                            />
                          )}
                          <LabelValueRow label="Witness" value={vote.witness} />
                          <LabelValueRow
                            label="Block Number"
                            value={vote.block_number.toString()}
                          />
                          <LabelValueRow label="Block ID" value={vote.block_id} />
                          <LabelValueRow
                            label="Transaction ID"
                            href={`${selectedNetwork.milkomedaExplorerBaseURL}/tx/${vote.transaction_id}`}
                            value={vote.transaction_id}
                          />
                        </dl>
                      ))
                    ) : (
                      <p className="mt-3 text-sm text-white">No votes found</p>
                    )}
                  </div>
                </Tabs.Content>
              </Tabs.Root>
            </div>
          </>
        )
      )}
    </div>
  );
}
