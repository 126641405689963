import AlgorandService from "./Mainchains/AlgorandService";
import { AssetElement } from "./BridgeService";
import CardanoService from "./Mainchains/CardanoService";

class TokensService {
  public static getAssetId = (
    selectedMainchain: string,
    assetIdFromIndexer: AssetElement
  ): string => {
    let assetId = "";
    switch (selectedMainchain) {
      case "Algorand":
        assetId = AlgorandService.extractAlgorandAssetId(assetIdFromIndexer.asset_id);
        break;
      case "Cardano":
        assetId = CardanoService.convertToAssetId(assetIdFromIndexer);
        break;
      default:
        break;
    }
    return assetId;
  };
}

export default TokensService;
