export default function NotFoundOrNotConfirmed() {
  return (
    <div className="px-8 pt-40 text-center text-white">
      <h1 className="text-4xl">Wrapping not yet confirmed</h1>
      <p className="my-4">
        Wrapping may not yet be confirmed. Please refresh the page in a couple of minutes.
      </p>
    </div>
  );
}
