import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Navigate, Route, Routes, useLocation } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Search from "./Pages/Search/Search";
import UnwrapTx from "./Pages/UnwrapTx";
import WrapTx from "./Pages/WrapTx";
import NotFound from "./Components/UI/Molecules/NotFound";
import { Fragment, useLayoutEffect } from "react";
import { Networks, networksMap } from "./Services/ConfigService";
import { AppProvider } from "./context/AppContext";
import Tokens from "./Pages/Tokens/Tokens";
import MainLayout from "./Components/layout/MainLayout";

export const networkList = Object.values(networksMap || {}).filter(
  (network) => network.id !== Networks.algorandDevnet
);
export const initialNetwork = networksMap[Networks.cardanoMainnet];

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 1, // 24 hours
    },
  },
});

const ScrollToTop = () => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return null;
};

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AppProvider>
        <BrowserRouter>
          <ScrollToTop />
          <MainLayout>
            <Routes>
              {/* By default redirect to mainnet */}
              <Route path="/" element={<Navigate to={`/${initialNetwork.id}`} replace />} />
              {networkList.map((network) => (
                <Fragment key={network.id}>
                  <Route
                    path={`/algorand-devnet`}
                    element={
                      <div className="caution-information">
                        <span>
                          <strong>Milkomeda A1 Devnet is no longer available.</strong>
                          <br />
                          <br />
                          <strong>
                            Milkomeda A1's validators will be shutting down at the end of May 2024.
                          </strong>

                          <p>
                            We urge anyone with funds on A1 to transfer them{" "}
                            <strong>within the next six months</strong> to ensure their safety.{" "}
                            <a
                              href="https://twitter.com/milkomeda_com/status/1730385308355588553?s=61&t=xg8fF6YJdZy52Uq_UwTGjA"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Read more here
                            </a>
                          </p>
                        </span>
                      </div>
                    }
                  />
                  <Route path={`/${network.id}`} element={<Home />} />
                  <Route path={`/${network.id}/search`} element={<Search />} />
                  <Route path={`/${network.id}/search/:type`} element={<Search />} />
                  <Route path={`/${network.id}/tokens`} element={<Tokens />} />
                  <Route path={`/${network.id}/wrap/:mainchainTxId`} element={<WrapTx />} />
                  <Route path={`/${network.id}/unwrap/:requestId`} element={<UnwrapTx />} />
                </Fragment>
              ))}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </MainLayout>
        </BrowserRouter>
      </AppProvider>
    </QueryClientProvider>
  );
}

export default App;
