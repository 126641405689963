import MilkomedaA1Logo from "../Assets/logos/milkomeda-a1.svg";
import MilkomedaC1Logo from "../Assets/logos/milkomeda-c1.svg";
import MilkomedaA1Icon from "../Assets/logos/milkomeda-a1-icon.svg";
import MilkomedaC1Icon from "../Assets/logos/milkomeda-c1-icon.svg";
import MilkomedaA1Circle from "../Assets/logos/milkomeda-a1-circle.svg";
import MilkomedaC1Circle from "../Assets/logos/milkomeda-c1-circle.svg";

import config from "../_config/config.json";

export type Network = {
  id: string;
  name: string;
  mainchain: string;
  wrapAssetSymbol: string;
  unwrapAssetSymbol: string;
  logo: string;
  icon: string;
  iconCard: string;
  hideIcon?: boolean;
  bridgeApiBaseURL: string;
  milkomedaExplorerBaseURL: string;
  milkomedaRPCURL: string;
  mainchainExplorerBaseURL: string;
  mainAssetDecimals: number;
  wrappingFee: string;
  unwrappingFee: string;
  evmDetails: EvmDetails;
  mainchainDetais?: {
    nodeUrl: string;
    apiKey: string;
  };
};

export type EvmDetails = {
  chainId: string;
  name: string;
  rpcUrl: string;
  unitName: string;
  shortUnitName: string;
  decimals: number;
};

export type ConfigMainValues = {
  bridgeApiBaseURL: string;
  milkomedaExplorerBaseURL: string;
  milkomedaRPCURL: string;
  mainchainExplorerBaseURL: string;
  mainchainNodeUrl?: string;
  defaultWrappingFee: string;
  defaultUnwrappingFee: string;
  evmDetails: EvmDetails;
};

export enum Networks {
  cardanoMainnet = "cardano-mainnet",
  cardanoDevnet = "cardano-devnet",
  algorandMainnet = "algorand-mainnet",
  algorandDevnet = "algorand-devnet",
}

const c1Mainnet = config["c1-mainnet"] as ConfigMainValues;
const c1Devnet = config["c1-devnet"] as ConfigMainValues;
const a1Mainnet = config["a1-mainnet"] as ConfigMainValues;
const a1Devnet = config["a1-devnet"] as ConfigMainValues;

const networksMap: Record<Networks, Network> = {
  [Networks.cardanoMainnet]: {
    id: Networks.cardanoMainnet,
    name: "Cardano Mainnet",
    mainchain: "Cardano",
    wrapAssetSymbol: "ADA",
    unwrapAssetSymbol: "MADA",
    logo: MilkomedaC1Logo,
    icon: MilkomedaC1Icon,
    iconCard: MilkomedaC1Circle,
    bridgeApiBaseURL: c1Mainnet.bridgeApiBaseURL ?? "",
    milkomedaExplorerBaseURL: c1Mainnet.milkomedaExplorerBaseURL ?? "",
    milkomedaRPCURL: c1Mainnet.milkomedaRPCURL ?? "",
    mainchainExplorerBaseURL: c1Mainnet.mainchainExplorerBaseURL ?? "",
    mainAssetDecimals: 18,
    wrappingFee: c1Mainnet.defaultWrappingFee,
    unwrappingFee: c1Mainnet.defaultUnwrappingFee,
    evmDetails: c1Mainnet.evmDetails,
  },
  [Networks.cardanoDevnet]: {
    id: Networks.cardanoDevnet,
    name: "Cardano Devnet",
    mainchain: "Cardano",
    wrapAssetSymbol: "ADA",
    unwrapAssetSymbol: "MADA",
    logo: MilkomedaC1Logo,
    icon: MilkomedaC1Icon,
    iconCard: MilkomedaC1Circle,
    hideIcon: true,
    bridgeApiBaseURL: c1Devnet.bridgeApiBaseURL ?? "",
    milkomedaExplorerBaseURL: c1Devnet.milkomedaExplorerBaseURL ?? "",
    milkomedaRPCURL: c1Devnet.milkomedaRPCURL ?? "",
    mainchainExplorerBaseURL: c1Devnet.mainchainExplorerBaseURL ?? "",
    mainAssetDecimals: 18,
    wrappingFee: c1Devnet.defaultWrappingFee,
    unwrappingFee: c1Devnet.defaultUnwrappingFee,
    evmDetails: c1Devnet.evmDetails,
  },
  [Networks.algorandMainnet]: {
    id: Networks.algorandMainnet,
    name: "Algorand Mainnet",
    mainchain: "Algorand",
    wrapAssetSymbol: "ALGO",
    unwrapAssetSymbol: "MALGO",
    icon: MilkomedaA1Icon,
    logo: MilkomedaA1Logo,
    iconCard: MilkomedaA1Circle,
    bridgeApiBaseURL: a1Mainnet.bridgeApiBaseURL ?? "",
    milkomedaExplorerBaseURL: a1Mainnet.milkomedaExplorerBaseURL ?? "",
    milkomedaRPCURL: a1Mainnet.milkomedaRPCURL ?? "",
    mainchainExplorerBaseURL: a1Mainnet.mainchainExplorerBaseURL ?? "",
    mainAssetDecimals: 6,
    wrappingFee: a1Mainnet.defaultWrappingFee,
    unwrappingFee: a1Mainnet.defaultUnwrappingFee,
    evmDetails: a1Mainnet.evmDetails,
    mainchainDetais: {
      nodeUrl: a1Mainnet.mainchainNodeUrl ?? "",
      apiKey: process.env.REACT_APP_ALGORAND_MAINNET_API_KEY ?? "",
    },
  },
  [Networks.algorandDevnet]: {
    id: Networks.algorandDevnet,
    name: "Algorand Devnet",
    mainchain: "Algorand",
    wrapAssetSymbol: "ALGO",
    unwrapAssetSymbol: "MALGO",
    icon: MilkomedaA1Icon,
    iconCard: MilkomedaA1Circle,
    hideIcon: true,
    logo: MilkomedaA1Logo,
    bridgeApiBaseURL: a1Devnet.bridgeApiBaseURL ?? "",
    milkomedaExplorerBaseURL: a1Devnet.milkomedaExplorerBaseURL ?? "",
    milkomedaRPCURL: a1Devnet.milkomedaRPCURL ?? "",
    mainchainExplorerBaseURL: a1Devnet.mainchainExplorerBaseURL ?? "",
    mainAssetDecimals: 6,
    wrappingFee: a1Devnet.defaultWrappingFee,
    unwrappingFee: a1Devnet.defaultUnwrappingFee,
    evmDetails: a1Devnet.evmDetails,
    mainchainDetais: {
      nodeUrl: a1Devnet.mainchainNodeUrl ?? "",
      apiKey: process.env.REACT_APP_ALGORAND_API_KEY ?? "",
    },
  },
  // [Networks.anotherChainToBeAdded]: {
  //   id: Networks.algorandDevnet,
  //   name: "Algorand Mainnet",
  //   icon: MilkomedaA1Icon,
  //   hideIcon: true,
  //   logo: MilkomedaA1Logo,
  //   bridgeApiBaseURL: a1Devnet.bridgeApiBaseURL?? "",
  //   milkomedaExplorerBaseURL: a1Devnet.bridgeApiBaseURL ?? "",
  //   milkomedaRPCURL: a1Devnet.bridgeApiBaseURL ?? "",
  //   mainchainExplorerBaseURL: a1Devnet.bridgeApiBaseURL ?? "",
  // },
};

export { networksMap };
