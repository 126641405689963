import { useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../Components/UI/Atoms/Loader";
import { BridgeWrapResponse, getWrappingRequestIdDetails } from "../Services/BridgeService";

import { ReactComponent as ChevronLeft } from "../Assets/chevron-left.svg";
import { useAppProvider } from "../context/AppContext";
import { RequestType, UtilService } from "../Services/UtilService";
import { MilkomedaService } from "../Services/MilkomedaService";
import { LocationState, ProcessedBridgeRequestResponse } from "../Types";
import TransactionCard from "../Components/UI/Molecules/TransactionCard/TransactionCard";
import AlgorandService from "../Services/Mainchains/AlgorandService";
import { Networks } from "../Services/ConfigService";
import * as Tabs from "@radix-ui/react-tabs";
import NotFoundOrNotConfirmed from "../Components/UI/Molecules/NotFoundOrNotConfirmed";

const LabelValueRow = ({ label, value, href }: { label: string; value: string; href?: string }) => {
  const valueComponent =
    href != null ? (
      <dl className="break-all text-white ">
        <a
          className="break-all text-white underline transition duration-300 ease-out hover:text-sky-400"
          href={href}
          target="_blank"
          rel="noreferrer noopener"
        >
          {value}
        </a>
      </dl>
    ) : (
      <dl className="break-all text-white">{value}</dl>
    );

  return (
    <div className="my-2 flex flex-col text-sm font-semibold md:flex-row">
      <dt className="min-w-[10rem] basis-0 text-gray-200 md:basis-40">{label}:</dt>
      {valueComponent}
    </div>
  );
};
const formatWrappingRequest = (item: BridgeWrapResponse): ProcessedBridgeRequestResponse => ({
  transaction_id: item.wrapping_assets[0].transaction_id,
  mainchain_tx_id: item.wrapping_details.mainchain_tx_id,
  from: "", // There's no from information for wrappings for now, hence we set the mainchain transaction id as "from"
  to: item.wrapping_details.to,
  block_number: item.wrapping_details.block_number,
  block_id: item.wrapping_details.block_id,
  timestamp: item.wrapping_details.block_timestamp.toString(),
  executed_timestamp: item.wrapping_assets[0].executed_timestamp,
  assets: item.wrapping_assets.slice(0, 1).map((asset: any) => ({
    asset_id: asset.asset_id,
    asset_value: asset.asset_value,
    request_id: asset.details.request_id,
    symbol: asset.details.symbol,
    mainchain_decimals: asset.details.mainchain_decimals,
    sidechain_decimals: asset.details.sidechain_decimals,
    cardano_policy: asset.details.cardano_policy,
    cardano_fingerprint: asset.details.cardano_fingerprint,
  })),
  invalidated: item.wrapping_assets[0].invalidated, // TODO
});

export default function WrapTx() {
  const { mainchainTxId } = useParams();
  const { selectedNetwork } = useAppProvider();
  const navigate = useNavigate();
  const location = useLocation();
  const { fromPathname } = (location.state as LocationState) ?? { fromPathname: null };

  const wrappingRequest = useQuery(
    ["wrapping-request", mainchainTxId],
    () => {
      return getWrappingRequestIdDetails({
        baseUrl: selectedNetwork.bridgeApiBaseURL,
        mainchainTxId: mainchainTxId,
      });
    },
    { enabled: !!mainchainTxId }
  );

  const executionState = MilkomedaService.getStatusOfRequestedItem(
    wrappingRequest.data?.wrapping_assets?.[0]?.executed_timestamp ?? null,
    undefined
  );

  const assetList = wrappingRequest.isSuccess
    ? wrappingRequest?.data?.wrapping_assets.map((asset) => asset.votes).flat(1)
    : [];

  return (
    <div className="position mx-auto flex w-full max-w-7xl flex-col px-5 py-9 pt-24">
      <button
        onClick={() => {
          if (fromPathname) {
            navigate(`/${selectedNetwork.id}${fromPathname}`);
            return;
          }
          navigate(`/${selectedNetwork.id}`);
        }}
        className="flex items-center"
      >
        <ChevronLeft className="mr-1" />
        <p className="text-sm text-gray-200">Back</p>
      </button>
      <h1 className="mt-3 mb-4 text-lg font-bold text-white">Transaction Details</h1>
      {wrappingRequest.isLoading ? (
        <Loader className="mt-5 flex h-6 w-full justify-center text-white md:mt-10 md:h-9" />
      ) : wrappingRequest.isSuccess && wrappingRequest.data == null ? (
        <NotFoundOrNotConfirmed />
      ) : (
        wrappingRequest.data && (
          <>
            <TransactionCard
              requestType={RequestType.WRAP}
              requestItem={formatWrappingRequest(wrappingRequest.data)}
            />

            <div className="mt-5 animate-fadeIn text-white transition-all duration-100">
              <Tabs.Root defaultValue="details" className="detail-tab">
                <Tabs.List className="tab-list">
                  <Tabs.Trigger value="details" className="tab">
                    Wrapping Request
                  </Tabs.Trigger>
                  <Tabs.Trigger value="assets" className="tab">
                    Assets
                  </Tabs.Trigger>
                  <Tabs.Trigger value="votes" className="tab">
                    Votes
                  </Tabs.Trigger>
                </Tabs.List>
                <Tabs.Content value="details" className="tab-content">
                  <LabelValueRow label="Status" value={executionState.toString()} />
                  <LabelValueRow
                    href={`${
                      selectedNetwork.mainchainExplorerBaseURL
                    }/${UtilService.getMainchainExplorerTxUrlBase(selectedNetwork.mainchain)}/${
                      wrappingRequest.data.wrapping_details.mainchain_tx_id
                    }`}
                    label={`${selectedNetwork.mainchain} Tx ID`}
                    value={wrappingRequest.data.wrapping_details.mainchain_tx_id}
                  />

                  <LabelValueRow
                    href={
                      wrappingRequest.data.wrapping_assets[0]?.transaction_id !== null
                        ? `${selectedNetwork.milkomedaExplorerBaseURL}/tx/${wrappingRequest.data.wrapping_assets[0]?.transaction_id}`
                        : undefined
                    }
                    label={`Milkomeda Tx ID`}
                    value={
                      wrappingRequest.data.wrapping_assets[0]?.transaction_id ??
                      "N.A. - awaiting for evm side"
                    }
                  />
                  <LabelValueRow
                    label="Stargate Address"
                    href={`${selectedNetwork.mainchainExplorerBaseURL}/address/${wrappingRequest.data.wrapping_details.stargate_address}`}
                    value={wrappingRequest.data.wrapping_details.stargate_address}
                  />
                  <LabelValueRow
                    label="To"
                    href={`${selectedNetwork.milkomedaExplorerBaseURL}/address/${wrappingRequest.data.wrapping_details.to}`}
                    value={wrappingRequest.data.wrapping_details.to}
                  />
                  <LabelValueRow
                    label="Block Number"
                    value={wrappingRequest.data.wrapping_details.block_number.toString()}
                  />

                  {selectedNetwork.mainchain === "Algorand" ? null : (
                    <LabelValueRow
                      label="Block ID"
                      value={wrappingRequest.data.wrapping_details.block_id}
                    />
                  )}
                </Tabs.Content>
                <Tabs.Content className="tab-content" value="assets">
                  <div className="space-y-8 divide-y-[1px] divide-gray-500">
                    {wrappingRequest.data.wrapping_assets.length ? (
                      wrappingRequest.data.wrapping_assets.map((asset) => {
                        const symbol = asset?.details?.symbol ?? selectedNetwork?.wrapAssetSymbol;
                        const assetSymbol =
                          selectedNetwork.id === Networks.algorandMainnet
                            ? AlgorandService.extractSymbolFromName(symbol)
                            : symbol;
                        const assetValue = UtilService.calculateAssetValue(asset, selectedNetwork);

                        return (
                          <dl className="" key={asset.asset_id}>
                            <LabelValueRow label="Asset ID" value={asset.asset_id} />
                            <LabelValueRow
                              label="Asset Value"
                              value={`${assetValue} ${assetSymbol}`}
                            />
                          </dl>
                        );
                      })
                    ) : (
                      <p className="mt-3 text-sm text-white">No assets found</p>
                    )}
                  </div>
                </Tabs.Content>
                <Tabs.Content className="tab-content" value="votes">
                  <div className="space-y-6 divide-y-[1px] divide-[#383534]">
                    {assetList.length ? (
                      assetList?.map((vote) => (
                        <div className="pt-5 first-of-type:pt-0" key={vote.voter}>
                          <LabelValueRow label="Voter" value={vote.voter} />
                          {vote.timestamp && (
                            <LabelValueRow
                              label="Timestamp"
                              value={UtilService.timestampToDateTime(vote.timestamp)}
                            />
                          )}
                          <LabelValueRow label="Block ID" value={vote.block_id} />
                          <LabelValueRow
                            label="Block Number"
                            value={vote.block_number.toString()}
                          />
                          <LabelValueRow
                            href={`${selectedNetwork.milkomedaExplorerBaseURL}/tx/${vote.transaction_id}`}
                            label="Vote Tx"
                            value={vote.transaction_id.toString()}
                          />
                        </div>
                      ))
                    ) : (
                      <p className="mt-3 text-sm text-white">No votes found</p>
                    )}
                  </div>
                </Tabs.Content>
              </Tabs.Root>
            </div>
          </>
        )
      )}
    </div>
  );
}
