import { TxStatus } from "../Types";

class MilkomedaService {
  // not removing this code for now since after moving dapp to explorer, maybe there will be possibility to use this
  // public static getErc20Contract(tokenAddress: string): ethers.Contract {
  //   const provider = new ethers.providers.JsonRpcProvider(Config.getMilkomedaRpcUrl);
  //   return new ethers.Contract(tokenAddress, ERC20Abi, provider);
  // }

  // public static async getTokenNameAsync(tokenAddress: string): Promise<string | undefined> {
  //   return (await MilkomedaService.getErc20Contract(tokenAddress).functions.symbol())[0];
  // }

  // public static async getTokenDecimalAsync(tokenAddress: string): Promise<number | undefined> {
  //   return (await MilkomedaService.getErc20Contract(tokenAddress).functions.decimals())[0];
  // }

  // public static async getTransactionByIdAsync(txId: string) {
  //   const provider = new ethers.providers.JsonRpcProvider(Config.getMilkomedaRpcUrl);
  //   return await provider.getTransaction(txId);
  // }

  // public static async getBestBlockAsync(): Promise<ethers.providers.Block> {
  //   const provider = new ethers.providers.JsonRpcProvider(Config.getMilkomedaRpcUrl);
  //   return await provider.getBlock("latest");
  // }

  public static getStatusOfRequestedItem(
    executedTimestamp?: number | null,
    invalidated?: boolean
  ): TxStatus {
    let executionState = TxStatus.Pending;
    if (invalidated === true) {
      executionState = TxStatus.Failed;
    } else if (executedTimestamp != null) {
      executionState = TxStatus.Confirmed;
    }
    return executionState;
  }
}

export { MilkomedaService };
